import { createContext } from 'react';
import { ContextValue } from './types';

const defaultValue = {
  openDrawer: false,
  handleDrawerOpen: () => {},
  menuItems: {
    general: [],
    inventory: [],
    administration: [],
    dashboard: [],
    'reporting & doc': [],
  },
  token: '',
};
export const context = createContext<ContextValue>(defaultValue);
export const { Provider } = context;
