import { makeStyles } from '@material-ui/styles';
import classNames from 'classnames';
import React from 'react';
import { OpenXLogo } from './components/OpenXLogo';
import { OpenXSelectLogo } from './components/OpenXSelectLogo';

const useStyles = makeStyles({
  lightMode: {
    '& > path.char': {
      fill: 'white',
    },
  },
  darkMode: {
    '& > path.char': {
      fill: '#010101',
    },
  },
});

interface LogoProps {
  className?: string;
  ['data-test']?: string;
  darkMode?: boolean;
  openAudience?: boolean;
}

/* eslint-disable max-len */
export function Logo(props: LogoProps): JSX.Element {
  const { className, darkMode, openAudience } = props;

  const classes = useStyles();
  const rootClass = classNames([className, darkMode ? classes.darkMode : classes.lightMode]);

  if (openAudience) {
    return <OpenXSelectLogo className={rootClass} data-test={props['data-test']} />;
  }

  return <OpenXLogo className={rootClass} data-test={props['data-test']} />;
}
