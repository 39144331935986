import React from 'react';

import { FormLabel, makeStyles, Typography } from '@material-ui/core';
import { CheckboxField, TextField, Paper, Loader } from '@openx/components/core';
import { FormFields, ExportTypes, ProviderFormState, mapRowsToLabels } from '../constants';

const useStyles = makeStyles({
  checkboxGroupWrapper: {
    marginLeft: '-10px',
    marginTop: '10px',
  },
  flexColumn: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: '10px',
  },
});

type Props = {
  values: ProviderFormState;
  nameFieldHandler: (value: string) => void;
  exportTypesFieldHandler: (value: string) => void;
  loading?: boolean;
};

export const ProviderForm = ({ values, nameFieldHandler, exportTypesFieldHandler, loading = false }: Props) => {
  const { checkboxGroupWrapper, flexColumn } = useStyles();

  const setName = value => {
    nameFieldHandler(value);
  };

  const setExportTypes = value => {
    exportTypesFieldHandler(value);
  };

  if (loading) {
    return (
      <Paper>
        <Loader />
      </Paper>
    );
  }

  return (
    <Paper data-test="providers-form">
      <Typography variant="h2" gutterBottom data-test="providers-form-title">
        Provider Details
      </Typography>
      <TextField
        value={values[FormFields.PROVIDER_ID]}
        label={mapRowsToLabels[FormFields.PROVIDER_ID]}
        placeholder="Enter Provider ID here"
        fullWidth
        disabled
        margin="dense"
        data-test="provider-id-input"
      />

      <TextField
        value={values[FormFields.PROVIDER_NAME]}
        label={mapRowsToLabels[FormFields.PROVIDER_NAME]}
        placeholder="Type your Provider name"
        fullWidth
        margin="dense"
        data-test="provider-name-input"
        onChange={e => setName(e.target.value)}
      />

      <div className={checkboxGroupWrapper}>
        <FormLabel data-test="allowed-export-types-label">Allowed Export Types</FormLabel>
        <div className={flexColumn}>
          <CheckboxField
            checked={values[FormFields.ALLOWED_EXPORT_TYPES].includes(ExportTypes.OA_MATCH)}
            label="Identity Graph"
            value={ExportTypes.OA_MATCH}
            data-test={`${ExportTypes.OA_MATCH}-input`}
            onChange={e => setExportTypes(e.target.value)}
          />
          <CheckboxField
            checked={values[FormFields.ALLOWED_EXPORT_TYPES].includes(ExportTypes.BIDSTREAM)}
            label="Bidstream"
            value={ExportTypes.BIDSTREAM}
            data-test={`${ExportTypes.BIDSTREAM}-input`}
            onChange={e => setExportTypes(e.target.value)}
          />
          <CheckboxField
            checked={values[FormFields.ALLOWED_EXPORT_TYPES].includes(ExportTypes.BIDSTREAM_PLUS)}
            label="Bidstream Plus"
            value={ExportTypes.BIDSTREAM_PLUS}
            data-test={`${ExportTypes.BIDSTREAM_PLUS}-input`}
            onChange={e => setExportTypes(e.target.value)}
          />
        </div>
      </div>
    </Paper>
  );
};
