import { useState, useEffect } from 'react';

function useHasSeenPopup(key = 'hasSeenPopup', defaultValue = false) {
  const [hasSeenPopup, setHasSeenPopup] = useState<boolean>(() => {
    const storedValue = localStorage.getItem(key);
    return storedValue ? JSON.parse(storedValue) : defaultValue;
  });

  useEffect(() => {
    localStorage.setItem(key, hasSeenPopup.toString());
  }, [key, hasSeenPopup]);

  return { hasSeenPopup, setHasSeenPopup } as const;
}

export default useHasSeenPopup;
