import React, { useCallback, useState } from 'react';
import { Dialog, DialogActions, DialogContent, Divider, IconButton, Box, Typography, Button } from '@material-ui/core';

import { REBRAND_INFO_URL, REBRAND_INFO_POPUP_EXPIRATION, REBRAND_INFO_POPUP_FLAG } from './constants';
import { TagNew } from './components/tagNew';
import { Trans, useTranslation } from 'react-i18next';
import { OpenXSelectLogo } from 'components/Logo/components/OpenXSelectLogo';
import CloseIcon from '@material-ui/icons/Close';
import { OutsideLink } from '@openx/components/core';
import { isPast } from './utils/isPast';
import useHasSeenPopup from './hooks/useHasSeenPopup';
import { makeStyles } from '@material-ui/core/styles';

const useRebrandStyles = makeStyles(theme => ({
  dialogTitleWrapper: {
    backgroundColor: theme.palette.background.boxLight,
    padding: theme.spacing(1.5, 2),
  },
  dialogStack: {
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  dialogLogo: {
    width: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(4),
  },
  dialogContent: {
    padding: '20px 16px 4px 16px',
  },
  dialogActions: {
    padding: '12px 16px',
  },
  customText: {
    lineHeight: '28px',
    marginBottom: '12px',
  },
  closeButton: {
    fontSize: '13px',
  },
  backgroundSvg: {
    position: 'absolute',
    top: 0,
    left: '10%',
  },
}));

const RebrandBackgroundImage = props => {
  return (
    <svg width="484" height="140" viewBox="0 0 484 140" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <line x1="166.499" y1="-34.9658" x2="119.499" y2="650.034" stroke="#B38673" strokeOpacity="0.12" />
      <line x1="71.4988" y1="-34.9658" x2="24.4988" y2="650.034" stroke="#B38673" strokeOpacity="0.12" />
      <line x1="261.499" y1="-34.9658" x2="214.499" y2="650.034" stroke="#B38673" strokeOpacity="0.12" />
      <line x1="356.499" y1="-34.9658" x2="309.499" y2="650.034" stroke="#B38673" strokeOpacity="0.12" />
      <line x1="455.499" y1="-26.9658" x2="408.499" y2="658.034" stroke="#B38673" strokeOpacity="0.12" />
      <line x1="0.0381904" y1="134.501" x2="483.038" y2="171.501" stroke="#B38673" strokeOpacity="0.12" />
      <line x1="0.0381904" y1="37.5015" x2="483.038" y2="74.5015" stroke="#B38673" strokeOpacity="0.12" />
    </svg>
  );
};

export const RebrandInfoPopup = () => {
  const { hasSeenPopup, setHasSeenPopup } = useHasSeenPopup(REBRAND_INFO_POPUP_FLAG);
  const isExpired = isPast(REBRAND_INFO_POPUP_EXPIRATION);
  const [isOpen, setIsOpen] = useState(!hasSeenPopup && !isExpired);
  const rebrandStyles = useRebrandStyles();
  const { t } = useTranslation();

  const onClose = useCallback(() => {
    setHasSeenPopup(true);
    setIsOpen(false);
  }, [setHasSeenPopup]);

  return (
    <Dialog open={isOpen} onClose={onClose} data-test="rebrand-info-popup">
      <Box className={rebrandStyles.dialogTitleWrapper}>
        <Box className={rebrandStyles.dialogStack}>
          <Box className={rebrandStyles.dialogLogo}>
            <OpenXSelectLogo darkMode={false} height={56} />
          </Box>

          {onClose && (
            <IconButton aria-label="Close dialog" onClick={onClose} size="small">
              <CloseIcon data-test="close-dialog" />
            </IconButton>
          )}
        </Box>

        <RebrandBackgroundImage className={rebrandStyles.backgroundSvg} />
      </Box>

      <DialogContent dividers className={rebrandStyles.dialogContent}>
        <TagNew />
        <Typography className={rebrandStyles.customText}>
          <Trans i18nKey="rebrand_info_popup_main_body">
            Exciting news! OpenAudience is now <b>OpenXSelect</b> — same platform, fresh identity.
            <br />
            We’ve also updated the terms and conditions to reflect our new platform, but no action from you is needed.
          </Trans>
        </Typography>
        <Typography className={rebrandStyles.customText}>
          <Trans i18nKey="rebrand_info_popup_redirect">
            <OutsideLink openInNewTab to={REBRAND_INFO_URL}>
              Click here
            </OutsideLink>{' '}
            to see what’s new.
          </Trans>
        </Typography>
      </DialogContent>

      <Divider />
      <DialogActions className={rebrandStyles.dialogActions}>
        <Button
          onClick={onClose}
          variant="contained"
          name="close"
          color="primary"
          className={rebrandStyles.closeButton}
        >
          {t('Close')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
