import { useMutation, useQuery } from '@apollo/client';
import { Grid, Box } from '@material-ui/core';
import { Loader, Paper } from '@openx/components/core';
import { PageHeader } from '@openx/components/header';

import { ActionBar } from 'components/ActionBar';
import { FormikHelpers as FormikActions } from 'formik';
import UpdateOrganization from 'graphql/mutation/organizations/UpdateOrganization.gql';
import AddAccountFeatureFlags from 'graphql/mutation/organizations/AddAccountFeatureFlags.gql';
import DeleteFeatureFlags from 'graphql/mutation/organizations/DeleteFeatureFlags.gql';
import GetOrganization from 'graphql/query/organizations/GetOrganization.gql';
import { NestedErrorCode, useLocalErrorHandling } from 'modules/graphql';
import { useSnackbar } from 'notistack';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { RouteOrganizationEditParams } from 'routes';
import {
  GetOrganizationQuery,
  GetOrganizationQueryVariables,
  UpdateOrganizationMutation,
  UpdateOrganizationMutationVariables,
} from 'types/schemaTypes';

import { Form, FormFields, FormValues } from '../components/Form';
import { DEFAULT_ALLOWED_COUNTRIES } from '../constants';
import { DEFAULT_ALLOWED_DOMAINS } from '../constants';
import { mapOrganizationToMutation } from '../mapOrganizationToMutation';
import { FeatureFlags, Fees } from '../OrganizationDetails/components';
import { useSideDrawer } from 'context';

export function OrganizationEdit(): JSX.Element {
  const { handleDrawerOpen, openDrawer, menuItems, token } = useSideDrawer();
  const history = useHistory<{ ref?: string }>();
  const { enqueueSnackbar } = useSnackbar();

  const [featureFlags, setFeatureFlags] = useState({});
  const [fees, setFees] = useState({});
  const [enableFees, setFeesEnabled] = useState(false);

  const { organizationId } = useParams<RouteOrganizationEditParams>();

  const {
    loading: organizationLoading,
    error: organizationError,
    data: { account_by_pk: organizationData = undefined } = {},
  } = useQuery<GetOrganizationQuery, GetOrganizationQueryVariables>(GetOrganization, {
    variables: { id: organizationId },
    fetchPolicy: 'no-cache',
  });

  const [updateOrganization, { loading: updateLoading }] = useMutation<
    UpdateOrganizationMutation,
    UpdateOrganizationMutationVariables
  >(UpdateOrganization, {
    refetchQueries: ['GetOrganizationsList'],
  });

  const [addAccountFeatureFlags, { loading: addFeatureFlagsLoading }] = useMutation(AddAccountFeatureFlags, {
    refetchQueries: ['GetOrganization, GetOrganizations'],
  });

  const [deleteFeatureFlags, { loading: deleteFeatureFlagsLoading }] = useMutation(DeleteFeatureFlags, {
    refetchQueries: ['GetOrganization, GetOrganizations'],
  });

  useLocalErrorHandling(
    UpdateOrganization,
    useCallback(
      error => {
        const mapOfPathToMessage = {
          name: 'Organization with such name already exists',
        };

        if (
          error.code === NestedErrorCode.SINGLE_UNIQUENESS_VIOLATION &&
          Object.keys(mapOfPathToMessage).includes(error.path)
        ) {
          enqueueSnackbar(mapOfPathToMessage[error.path], { variant: 'error' });
          return true;
        }

        return false;
      },
      [enqueueSnackbar],
    ),
  );

  const initialValues: FormValues = useMemo(
    () => ({
      [FormFields.NAME]: organizationData?.name || '',
      [FormFields.DESCRIPTION]: organizationData?.description || '',
      [FormFields.PARENT_ORGANIZATION_ID]: organizationData?.parent_account?.id || '',
      [FormFields.ALLOWED_COUNTRIES]: organizationData?.allowed_countries || DEFAULT_ALLOWED_COUNTRIES,
      [FormFields.ALLOWED_DOMAINS]: organizationData?.allowed_domains || DEFAULT_ALLOWED_DOMAINS,
      [FormFields.EXCHANGE_ACCOUNT_PARTNER_ID]: organizationData?.exchange_account_partner_id || '',
    }),
    [organizationData],
  );

  useEffect(() => {
    // @ts-ignore TODO:
    const featureFlags = (organizationData?.feature_flags || []).reduce(
      (acc, { value: featureFlag }) => ({
        ...acc,
        [featureFlag]: true,
      }),
      {},
    );

    if (
      organizationData?.exchange_account_partner_id !== '' &&
      organizationData?.exchange_account_partner_id !== null
    ) {
      setFeesEnabled(true);
      setFees({
        max_cpm_cap: organizationData?.max_cpm_cap,
        max_share: organizationData?.max_share,
        default_platform_share: organizationData?.default_platform_share,
      });
    }

    setFeatureFlags(featureFlags);
  }, [organizationData]);

  const organizations = useMemo(
    () => [{ id: initialValues.parentOrganizationId, name: organizationData?.parent_account?.name || '' }],
    [initialValues, organizationData],
  );

  const goBack = useCallback(
    () => (history.location.state?.ref ? history.goBack() : history.push('/organizations')),
    [history],
  );

  const handleFeatureFlagChange = featureFlag => isChecked => {
    setFeatureFlags(prev => ({
      ...prev,
      [featureFlag]: isChecked,
    }));
  };

  const handleFeeChange = fee => value => {
    setFees(prev => ({
      ...prev,
      [fee]: value,
    }));
  };

  const objects = useMemo(
    () =>
      Object.keys(featureFlags).reduce<any>(
        (acc, value) => (featureFlags[value] ? [...acc, { value, account_id: organizationId }] : [...acc]),
        [],
      ),
    [featureFlags],
  );

  const handleExchangePartnerIdField = status => {
    setFeesEnabled(status);
  };

  const handleSubmit = async (values: FormValues, actions: FormikActions<FormValues>) => {
    try {
      await updateOrganization({
        variables: {
          id: organizationId,
          data: mapOrganizationToMutation(
            { ...values, ...fees },
            {
              default_platform_share: organizationData?.default_platform_share,
              max_share: organizationData?.max_share,
            },
          ),
        },
      });

      deleteFeatureFlags({
        variables: {
          organizationId,
        },
      });

      addAccountFeatureFlags({
        variables: {
          objects,
        },
      });

      goBack();
      enqueueSnackbar('Organization updated successfully', { variant: 'success' });
    } finally {
      actions.setSubmitting(false);
    }
  };

  const loading = organizationLoading || addFeatureFlagsLoading || deleteFeatureFlagsLoading;

  if (loading) {
    return (
      <Paper>
        <Loader />
      </Paper>
    );
  }

  if (organizationError) {
    throw new Error('Could not get the organization data');
  }

  return (
    <ActionBar onGoBack={goBack}>
      <PageHeader
        title={`Edit: ${initialValues.name}`}
        titlePrefix="OpenXSelect"
        openDrawer={openDrawer}
        handleDrawerOpen={handleDrawerOpen}
        menuItems={menuItems}
        token={token}
      >
        <Grid container justifyContent="center">
          <Grid item xs={6}>
            <Paper>
              <Form
                onSubmit={handleSubmit}
                initialValues={initialValues}
                loading={updateLoading}
                organizations={organizations}
                isEdit={true}
                onExchangePartnerChange={handleExchangePartnerIdField}
              />
            </Paper>
            <Box mt={3}>
              <FeatureFlags
                loading={loading}
                readonly={false}
                featureFlagsState={featureFlags}
                // @ts-ignore TODO:
                onChange={handleFeatureFlagChange}
              />
            </Box>
            <Box mt={3}>
              <Fees
                loading={loading}
                fees={fees}
                isEdit={true}
                initialValues={initialValues}
                isEnabled={enableFees}
                // @ts-ignore TODO:
                onChange={handleFeeChange}
              />
            </Box>
          </Grid>
        </Grid>
      </PageHeader>
    </ActionBar>
  );
}
