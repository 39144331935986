import React from 'react';

import { ExpiresAtCell } from './ExpiresAtCell';
import { DateCell } from 'components/DateCell';
import { Columns } from '@openx/components/table';
import { ApiKeysRow } from './APIKeysSection';
import { LinkedProvidersRow } from './LinkedProviders';

export const apiKeys: Columns<ApiKeysRow> = [
  {
    title: 'Name',
    key: 'first_name',
    width: '50%',
    style: {
      paddingTop: '13px',
      paddingBottom: '13px',
    },
    render: ({ first_name }) => <b>{first_name}</b>,
  },
  {
    title: 'Expiration Date',
    key: 'expires_at',
    width: '25%',
    render: ({ expires_at }) => {
      return <ExpiresAtCell expiresAt={expires_at} />;
    },
  },
  {
    title: 'Date Created',
    key: 'created_date',
    width: '25%',
    render: ({ created_date }) => <DateCell date={created_date} />,
  },
];

export const linkedProviders: Columns<LinkedProvidersRow> = [
  {
    title: 'Provider Name',
    key: 'name',
    width: '43%',
    style: {
      paddingTop: '13px',
      paddingBottom: '13px',
    },
    render: ({ name }) => <b>{name}</b>,
  },
  {
    title: 'Identity Graph',
    key: 'oa_match',
    width: '15%',
    render: ({ allowed_export_types }) => (allowed_export_types.includes('oa_match') ? 'Yes' : 'No'),
  },
  {
    title: 'Bidstream',
    key: 'bidstream',
    width: '15%',
    render: ({ allowed_export_types }) => (allowed_export_types.includes('bidstream') ? 'Yes' : 'No'),
  },
  {
    title: 'Bidstream Plus',
    key: 'bidstream_plus',
    width: '22%',
    render: ({ allowed_export_types }) => (allowed_export_types.includes('bidstream_plus') ? 'Yes' : 'No'),
  },
];
