export const COLORS = {
  REGAL: '#0076BD',
  AQUA: '#1FBAB0',
  WHITE: '#FFF',

  HONEY: '#FBC459',
  HONEY008: '#FDF9F0',
  RASPBERRY: '#D83158',
  MENTHOL: '#25D09D',
  SKY: '#F2F8FC',
  CORN: '#FEDF71',
  CREME: '#B38773',

  GRAY_LIGHT: '#E7E7E7',
  BLACK_03: '#FAFAFA',
  BLACK_10: '#E6E6E6',
  BLACK_30: '#B3B3B3',
  GRAY_DARK: '#B5B5B5',
  BACKGROUND: '#EFEFEF',
  BOX_LIGHT: '#FBFBFB',
  BOX_DARK: '#F6F6F6',
  TEXT_DARK: '#0D0D0D',
  TEXT_LIGHT: '#666666',
  CHIP_BACKGROUND_CREME: '#B387730D',
};

export const palette = {
  primary: {
    main: COLORS.REGAL,
    contrastText: COLORS.WHITE,
  },
  secondary: {
    main: COLORS.AQUA,
    contrastText: COLORS.WHITE,
  },
  background: {
    default: COLORS.BACKGROUND,
    paper: COLORS.WHITE,
    sky: COLORS.SKY,
    boxLight: COLORS.BOX_LIGHT,
    boxDark: COLORS.BOX_DARK,
    grayLight: COLORS.GRAY_LIGHT,
    black03: COLORS.BLACK_03,
    black10: COLORS.BLACK_10,
    black30: COLORS.BLACK_30,
    honey008: COLORS.HONEY008,
    raspberry: COLORS.RASPBERRY,
    creme: COLORS.CHIP_BACKGROUND_CREME,
  },
  border: COLORS.BLACK_10,
  divider: COLORS.GRAY_LIGHT,
  creme: COLORS.CREME,
  text: {
    primary: COLORS.TEXT_DARK,
    secondary: COLORS.TEXT_LIGHT,
    disabled: COLORS.TEXT_LIGHT,
    hint: COLORS.TEXT_LIGHT,
  },
  error: {
    main: COLORS.RASPBERRY,
    contrastText: COLORS.WHITE,
  },
  warning: {
    light: COLORS.CORN,
    main: COLORS.HONEY,
    contrastText: COLORS.WHITE,
  },
  success: {
    main: COLORS.MENTHOL,
    contrastText: COLORS.WHITE,
  },
  action: {
    inactive: COLORS.GRAY_LIGHT,
    disabled: COLORS.GRAY_DARK,
  },
};
