import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { isEqual } from 'lodash';
import { Grid } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { useQuery, useMutation } from '@apollo/client';

import { PageHeader } from '@openx/components/header';
import { Loader } from '@openx/components/core';
import { ActionBar } from 'components/ActionBar';
import { ContentWrapper } from 'components/ContentWrapper';
import GetAudience from 'graphql/query/audiences/GetAudience.gql';
import UpdateAudience from 'graphql/mutation/audiences/UpdateAudience.gql';
import ActivateAudience from 'graphql/mutation/audiences/ActivateAudience.gql';
import { getCurrentUser } from 'firebaseIntegration/utils';
import createLog from 'graphql/mutation/error/createLog.gql';
import {
  useAudience,
  withAudience,
  ActionType,
  getIsStateSynced,
  getExportType,
  getIncludesField,
  NormalizedDefinitionItem,
  fields,
  getName,
  getDescription,
  getExcludesGroups,
  GroupItem,
  getAudienceShares,
  defaultShareRow,
  AudienceShare as LocalAudienceShare,
  getExportTargets,
} from 'modules/audiences/AudienceProvider';
import {
  GetAudienceQuery,
  GetAudienceQueryVariables,
  UpdateAudienceMutation,
  UpdateAudienceMutationVariables,
  ActivateAudienceMutation,
  ActivateAudienceMutationVariables,
  AudienceShare,
} from 'types/schemaTypes';
import { AccessForbiddenPage } from 'modules/auth/AccessForbiddenPage';
import { RouteAudienceEditParams } from 'routes';
import { AudienceCriteria, Details, AllowedOrganizations, WorkOnBehalf } from 'modules/audiences/components';
import { useProviders, useDemographicsOptions, defaultAgeRange, useAudienceShares } from 'modules/audiences/hooks';
import { omitEmptyGroupsAndSubgroups } from 'modules/audiences/utils/areSegmentsEmpty';
import { AudienceSharesByFee } from 'modules/audiences/components/AudienceShare/types';
import { useSideDrawer } from 'context';

import {
  mapAudienceToUpdateMutation,
  mapAudienceToProvider,
  canEditAudience,
  validateAudienceForm,
  isCustomExportSelected,
} from '../utils';
import { NestedErrorCode, useLocalErrorHandling } from '../../graphql';
import { EditAudienceConfirmationDialog as ConfirmationDialog } from './ConfirmationDialog';
import { isSegmentErrorMessage, parseSegmentErrorMessages } from '../utils/parseSegmentErrorMessages';
import { isAdmin, isInternalAustralianUser, isInternalUser } from 'permissions';
import { useSession } from 'modules/auth/AuthProvider';
import { AudienceOptions, AudienceType } from 'modules/deals/components/AudienceOptions';

const { NORMALIZED_DEFINITION_FIELD } = fields;

function AudienceEditContent(): JSX.Element {
  const session = useSession();
  const { handleDrawerOpen, openDrawer, menuItems, token } = useSideDrawer();
  const history = useHistory<{ ref?: string }>();
  const [initialShareRows, setInitialShareRows] = useState<AudienceSharesByFee[] | null>(null);

  const { audienceId } = useParams<RouteAudienceEditParams>();
  const { enqueueSnackbar } = useSnackbar();
  const { state, dispatch } = useAudience();
  const { providers } = useProviders();
  const { rangeToSegments } = useDemographicsOptions();

  const {
    createConfirmationHandler,
    loading: shareLoading,
    error: shareError,
    useValidation,
  } = useAudienceShares({ audienceId });

  const { validationError, isSubmissionDisabled, touchAllFields } = useValidation;

  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] = useState(false);
  const [isUpdateOnlyAvaliable, setIsUpdeteOnlyAvaliable] = useState(false);

  const prevIncludes = useRef<NormalizedDefinitionItem>();
  const prevExcludes = useRef<GroupItem[]>();
  const prevName = useRef<string>();
  const prevDescription = useRef<string>();
  const prevShares = useRef<LocalAudienceShare[]>();
  const prevExportTargets = useRef<string[]>();

  const currIncludes = useMemo(() => getIncludesField(state), [state]);
  const currExcludes = useMemo(() => omitEmptyGroupsAndSubgroups(getExcludesGroups(state)), [state]);
  const currName = useMemo(() => getName(state), [state]);
  const currDescription = useMemo(() => getDescription(state), [state]);
  const audienceShares = useMemo(() => getAudienceShares(state), [state]);
  const currExportTargets = useMemo(() => getExportTargets(state), [state]);

  useEffect(() => {
    if (initialShareRows === null && !isEqual(audienceShares, [defaultShareRow])) {
      //@ts-ignore
      setInitialShareRows(audienceShares);
    }
  }, [audienceShares]);

  const isDefinitionsEqual = isEqual(currIncludes, prevIncludes.current) && isEqual(currExcludes, prevExcludes.current);
  const haveSharesChanged = !isEqual(prevShares.current, audienceShares) && !isEqual(audienceShares, [defaultShareRow]);

  const [pushLog] = useMutation(createLog);

  const shareAudience = createConfirmationHandler(initialShareRows, audienceShares);

  const {
    error: audienceError,
    data: { audience_by_pk: audienceData = undefined } = {},
    loading: audienceLoading,
  } = useQuery<GetAudienceQuery, GetAudienceQueryVariables>(GetAudience, {
    variables: { id: audienceId },
    skip: !audienceId,
    onCompleted: ({ audience_by_pk } = {}) => {
      if (audience_by_pk?.is_archived) {
        history.push(`/audiences/${audienceId}`);
        return;
      }

      if (audience_by_pk) {
        dispatch({
          type: ActionType.SET_AUDIENCE,
          payload: mapAudienceToProvider(audience_by_pk, providers),
        });
      }
    },
  });

  const [updateAudience, { loading: updateLoading }] = useMutation<
    UpdateAudienceMutation,
    UpdateAudienceMutationVariables
  >(UpdateAudience, {
    refetchQueries: ['GetAudienceFeed', 'GetAudiencesList'],
  });

  const [activateAudience, { loading: activateLoading }] = useMutation<
    ActivateAudienceMutation,
    ActivateAudienceMutationVariables
  >(ActivateAudience, {
    refetchQueries: ['GetAudienceFeed'],
  });

  const hasAccessToEditPage = audienceData && canEditAudience(audienceData);
  const isStateSynced = getIsStateSynced(state);
  const customExportSelected = isCustomExportSelected(getExportType(state));

  useLocalErrorHandling(
    UpdateAudience,
    useCallback(
      error => {
        if (error.code === NestedErrorCode.BAD_USER_INPUT) {
          enqueueSnackbar(parseSegmentErrorMessages(error.message, true), { variant: 'error' });
          return true;
        }

        if (error.code === NestedErrorCode.JOINT_UNIQUENESS_VIOLATION) {
          enqueueSnackbar('Audience with such name already exists', { variant: 'error' });
          return true;
        }

        return false;
      },
      [enqueueSnackbar],
    ),
  );

  const goBack = useCallback(
    () => (history.location.state?.ref ? history.goBack() : history.push('/audiences')),
    [history],
  );

  const onFormSubmit = useCallback(async () => {
    if (validationError) return;

    try {
      await (haveSharesChanged && shareAudience());

      await updateAudience({
        variables: {
          data: mapAudienceToUpdateMutation(state),
          id: audienceId,
        },
      });

      await activateAudience({ variables: { id: audienceId } });

      goBack();
      enqueueSnackbar('Audience updated successfully', { variant: 'success' });
    } catch (error) {
      const { message } = error as { message: string };

      message !== 'Validation error' &&
        !isSegmentErrorMessage(message) &&
        enqueueSnackbar(message, { variant: 'error' });

      const user = await getCurrentUser();
      await pushLog({
        variables: {
          type: error,
          location: window.location.href,
          msg: message,
          name: user?.displayName,
          email: user?.email,
        },
      });
    }
  }, [
    validationError,
    haveSharesChanged,
    shareAudience,
    updateAudience,
    state,
    audienceId,
    activateAudience,
    history,
    enqueueSnackbar,
    pushLog,
  ]);

  async function saveWithoutRepublishing() {
    await updateAudience({
      variables: {
        data: mapAudienceToUpdateMutation(state),
        id: audienceId,
      },
    })
      .then(() => {
        goBack();
        enqueueSnackbar('Audience updated successfully', { variant: 'success' });
      })
      .catch(() => {});
  }

  useEffect(() => {
    if (isStateSynced) {
      prevIncludes.current = currIncludes;
      prevExcludes.current = omitEmptyGroupsAndSubgroups(currExcludes);
      prevName.current = currName;
      prevDescription.current = currDescription;
      prevShares.current = audienceShares;
      prevExportTargets.current = currExportTargets;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isStateSynced]);

  useEffect(() => {
    if (isDefinitionsEqual && (prevName.current !== currName || prevDescription.current !== currDescription)) {
      setIsUpdeteOnlyAvaliable(true);
    } else {
      setIsUpdeteOnlyAvaliable(false);
    }
  }, [currName, currDescription, isDefinitionsEqual]);

  const openConfirmationDialog = async () => {
    touchAllFields();

    if (validationError) return;

    try {
      validateAudienceForm(state);
    } catch (error) {
      const { message } = error as { message: string };
      enqueueSnackbar(message, { variant: 'error' });

      throw error;
    }

    if (
      !isEqual(currIncludes, prevIncludes.current) ||
      !isEqual(currExcludes, prevExcludes.current) ||
      !isEqual(initialShareRows, audienceShares) ||
      !isEqual(currExportTargets, prevExportTargets) ||
      prevName.current !== currName ||
      prevDescription.current !== currDescription
    ) {
      setIsConfirmationDialogOpen(true);
    } else {
      onFormSubmit();
    }
  };

  const closeConfirmationDialog = () => {
    setIsConfirmationDialogOpen(false);
  };

  if (audienceError || shareError) {
    throw new Error('Cannot fetch audience');
  }

  if (audienceLoading || !isStateSynced) {
    return <Loader />;
  }

  if (!hasAccessToEditPage) {
    return <AccessForbiddenPage />;
  }

  const isInternalOxUser = isInternalAustralianUser(session) || isInternalUser(session);
  const isInternalOxAdmin = isAdmin(session) && isInternalOxUser;

  return (
    <>
      <ActionBar
        actions={[
          {
            type: 'button',
            label: 'Save',
            props: {
              color: 'primary',
              disabled: isSubmissionDisabled,
              type: 'submit',
              onClick: openConfirmationDialog,
              loading: updateLoading || activateLoading || shareLoading,
            },
          },
        ]}
        onGoBack={goBack}
      />
      <PageHeader
        title={`Edit: ${audienceData?.name}`}
        titlePrefix="OpenXSelect"
        openDrawer={openDrawer}
        handleDrawerOpen={handleDrawerOpen}
        menuItems={menuItems}
        token={token}
      >
        <ContentWrapper>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              {isInternalOxAdmin && (
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <WorkOnBehalf disabled />
                  </Grid>
                </Grid>
              )}
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <AudienceOptions isEdit={true} type={AudienceType.USE_DIRECT_AUDIENCE} />
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Details />
                </Grid>
                <Grid item xs={12}>
                  <AllowedOrganizations
                    useValidation={useValidation}
                    audience={{ id: audienceId, name: currName }}
                    audienceShares={audienceShares as AudienceShare[]}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <AudienceCriteria
                isAudienceTypeSelected
                customExportSelected={customExportSelected}
                boxesVisible
                readonly={false}
              />
            </Grid>
          </Grid>
        </ContentWrapper>
      </PageHeader>

      <ConfirmationDialog
        isLoading={updateLoading || activateLoading || shareLoading}
        isOpen={isConfirmationDialogOpen}
        audienceName={audienceData?.name || ''}
        audienceId={audienceId}
        onCancel={closeConfirmationDialog}
        onConfirmed={onFormSubmit}
        secondaryAction={saveWithoutRepublishing}
        isUpdateOnlyAvaliable={isUpdateOnlyAvaliable}
      />
    </>
  );
}

export const AudienceEdit = withAudience(AudienceEditContent);
