import React, { useState, useCallback, memo, useEffect } from 'react';
import { isEqual } from 'lodash';
import { useHistory } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { PageHeader } from '@openx/components/header';

import { ActionBar } from 'components/ActionBar';
import { ContentWrapper } from 'components/ContentWrapper';
import {
  withAudience,
  useAudience,
  ActionType,
  getExportType,
  getDirectProvider,
  getAccountId,
} from 'modules/audiences/AudienceProvider';
import { useSession } from 'modules/auth/AuthProvider';
import { Details, AudienceCriteria, WorkOnBehalf } from 'modules/audiences/components';
import { AudienceOptions, AudienceType, OnChangeArgs } from 'modules/deals/components/AudienceOptions';

import { useCreateAndActivate } from './useCreateAndActivate';
import { isCustomExportSelected } from '../utils';
import { useSideDrawer } from 'context';
import { ComparisonType } from 'types/common';
import { isAdmin, isInternalAustralianUser, isInternalUser } from 'permissions';

interface HistoryState {
  deal?: any;
  package?: any;
  redirectPath?: string;
  accountId?: string;
  useUniqueTargeting?: boolean;
  useCuratedTemplate?: boolean;
}

function AudienceCreateContent(): JSX.Element {
  const history = useHistory<HistoryState>();
  const { enqueueSnackbar } = useSnackbar();
  const session = useSession();
  const { state, dispatch } = useAudience();
  const [createAndActivateAudience, { loading }] = useCreateAndActivate(
    getAccountId(state) ?? session.account.id,
    session.id,
  );
  const { handleDrawerOpen, openDrawer, menuItems, token } = useSideDrawer();

  const [options, setOptions] = useState<OnChangeArgs>({});

  const exportType = getExportType(state);
  const directProvider = getDirectProvider(state);

  const displayDirectAudienceBox = !!(directProvider && exportType);
  const customExportSelected = isCustomExportSelected(exportType);
  const customAudienceSelected = !!options.audienceId;

  const isAudienceTypeSelected =
    options.type === AudienceType.CREATE || displayDirectAudienceBox || customAudienceSelected;

  const boxesVisible =
    options.type === AudienceType.CREATE ||
    customAudienceSelected ||
    (options.type === AudienceType.USE_DIRECT_AUDIENCE && displayDirectAudienceBox);

  const isInternalOxUser = isInternalAustralianUser(session) || isInternalUser(session);
  const isInternalOxAdmin = isAdmin(session) && isInternalOxUser;

  const historyState = history.location.state;

  useEffect(() => {
    dispatch({
      type: ActionType.SET_ACCOUNT_ID,
      payload: { account_id: historyState?.accountId ?? session.account.id },
    });
  }, [session.account.id, historyState?.accountId, dispatch]);

  const onAudienceOptionsChange = useCallback(
    (nextOptions: OnChangeArgs) => {
      if (options.type !== nextOptions.type) {
        dispatch({ type: ActionType.RESET_AUDIENCE });
        setOptions({ audienceId: undefined, ...nextOptions });
        dispatch({ type: ActionType.SET_ACCOUNT_ID, payload: { account_id: state?.account_id ?? session.account.id } });
        dispatch({
          type: ActionType.SET_TEMPLATE_NAME,
          payload: { templateName: nextOptions.audienceName || undefined },
        });
      } else {
        setOptions({ ...options, ...nextOptions });
      }
    },
    [options, dispatch, state?.account_id, session.account.id],
  );

  async function onFormSubmit() {
    try {
      const audienceId = await createAndActivateAudience(state);

      if (history.location.key === 'default' && historyState?.redirectPath) {
        const formKey = historyState.redirectPath.startsWith('/deals') ? 'deal' : 'package';

        const formObject = historyState[formKey];

        if (formObject) {
          formObject.targeting = {
            ...(formObject?.targeting ?? {}),
            audience: {
              openaudience_custom: {
                op: ComparisonType.INTERSECTS,
                val: `openaudience-${audienceId}`,
              },
            },
          };
        }

        return history.push(historyState.redirectPath, {
          [formKey]: formObject ?? null,
          useCuratedTemplate: historyState?.useCuratedTemplate,
          useUniqueTargeting: historyState?.useUniqueTargeting,
        });
      }

      history.push('/audiences');
      enqueueSnackbar('Audience created successfully', { variant: 'success' });
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <>
      <ActionBar
        actions={[
          {
            type: 'button',
            label: 'Save',
            props: {
              color: 'primary',
              type: 'submit',
              onClick: onFormSubmit,
              loading,
              disabled: !boxesVisible,
            },
          },
        ]}
        onGoBack={() => history.goBack()}
      />
      <PageHeader
        title="Create New Audience"
        titlePrefix="OpenXSelect"
        openDrawer={openDrawer}
        handleDrawerOpen={handleDrawerOpen}
        menuItems={menuItems}
        token={token}
      >
        <ContentWrapper>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              {isInternalOxAdmin && (
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <WorkOnBehalf disabled={!!historyState?.accountId} onOrganizationChange={() => setOptions({})} />
                  </Grid>
                </Grid>
              )}
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <AudienceOptions
                    onChange={onAudienceOptionsChange}
                    options={[AudienceType.CREATE, AudienceType.BASE, AudienceType.USE_DIRECT_AUDIENCE]}
                    {...options}
                  />
                </Grid>

                {boxesVisible && (
                  <Grid item xs={12}>
                    <Details />
                  </Grid>
                )}
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <AudienceCriteria
                isAudienceTypeSelected={isAudienceTypeSelected}
                customExportSelected={customExportSelected}
                boxesVisible={boxesVisible}
                readonly={false}
              />
            </Grid>
          </Grid>
        </ContentWrapper>
      </PageHeader>
    </>
  );
}

export const AudienceCreate = withAudience(
  memo(AudienceCreateContent, (prevProps, nextProps) => isEqual(nextProps, prevProps)),
);
