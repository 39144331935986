import React from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import { Error, ForbiddenImage } from '@openx/components/core';

import { TOP_BAR_HEIGHT } from 'config';

const useStyles = makeStyles({
  error: {
    height: `calc(100vh - ${TOP_BAR_HEIGHT}px)`,
  },
});

export const AccessForbiddenPage = (): JSX.Element => {
  const history = useHistory();
  const classes = useStyles();

  return (
    <Error
      title="Forbidden"
      titlePrefix="OpenXSelect"
      subtitle="You came to the wrong space. You shall not pass!"
      className={classes.error}
      PictureComponent={ForbiddenImage}
      data-test="access-forbidden"
      actions={[
        {
          color: 'secondary',
          variant: 'contained',
          onClick: history.goBack,
          children: 'Take me home please',
          'data-test': 'button-back',
        },
      ]}
    />
  );
};
