import React from 'react';
import { useTranslation } from 'react-i18next';
import { Chip, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme: Theme) => ({
  chip: {
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.background.creme,
    border: `1px solid ${theme.palette.creme}`,
    paddingLeft: '8px',
    paddingRight: '12px',
    fontSize: '12px',
    marginBottom: '8px',
    borderRadius: '28px',
    height: '28px',
    '&:hover': {
      backgroundColor: theme.palette.background.creme,
    },
  },
  svgIcon: {
    marginRight: '4px',
  },
}));

const StarsImage = props => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M14.25 6.75L15.1875 4.6875L17.25 3.75L15.1875 2.8125L14.25 0.75L13.3125 2.8125L11.25 3.75L13.3125 4.6875L14.25 6.75ZM8.625 7.125L6.75 3L4.875 7.125L0.75 9L4.875 10.875L6.75 15L8.625 10.875L12.75 9L8.625 7.125ZM14.25 11.25L13.3125 13.3125L11.25 14.25L13.3125 15.1875L14.25 17.25L15.1875 15.1875L17.25 14.25L15.1875 13.3125L14.25 11.25Z"
        fill="#B38773"
      />
    </svg>
  );
};

export const TagNew = () => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Chip className={classes.chip} icon={<StarsImage className={classes.svgIcon} />} label={t('NEW')} size="small" />
  );
};
