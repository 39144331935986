import React, { useCallback, useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import { FormControlLabel, Theme, Typography, makeStyles } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

import { Button, Checkbox, Loader } from '@openx/components/core';
import SetTermsAndConditionsAccepted from 'graphql/mutation/users/SetTermsAndConditionsAccepted.gql';
import { useAuth, useSession } from 'modules/auth/AuthProvider';
import {
  SetTermsAndConditionsAcceptedMutation,
  SetTermsAndConditionsAcceptedMutationVariables,
} from 'types/schemaTypes';

import { Content } from './Content';
import { Layout } from './Layout';

const useStyles = makeStyles<Theme>(theme => ({
  checkboxControl: {
    alignItems: 'flex-start',
    marginTop: theme.spacing(2),
  },
  checkboxWrapper: {
    marginTop: -theme.spacing(1),
  },
}));

export const TermsAcceptance = () => {
  const classes = useStyles();
  const { logout, reloadSession } = useAuth();
  const history = useHistory();
  const session = useSession();
  const [termsAccepted, setTermsAccepted] = useState(false);

  const [setTermsAndConditionsAccepted, termsMutation] = useMutation<
    SetTermsAndConditionsAcceptedMutation,
    SetTermsAndConditionsAcceptedMutationVariables
  >(SetTermsAndConditionsAccepted);

  useEffect(() => {
    if (session?.terms_and_conditions_accepted) {
      history.replace('/');
    }
  }, [history, session]);

  const onCheckedChange = useCallback((_, checked) => {
    setTermsAccepted(checked);
  }, []);

  const onConfirm = useCallback(async () => {
    if (!termsAccepted) return;

    await setTermsAndConditionsAccepted({
      variables: {
        accepted: termsAccepted,
        date: new Date().toISOString(),
        id: session.id,
      },
    });
    reloadSession();
  }, [reloadSession, session.id, setTermsAndConditionsAccepted, termsAccepted]);

  if (session?.terms_and_conditions_accepted) {
    return <Loader />;
  }

  return (
    <Layout
      content={<Content />}
      checkbox={
        <FormControlLabel
          classes={{ root: classes.checkboxControl }}
          control={
            <div className={classes.checkboxWrapper}>
              <Checkbox onChange={onCheckedChange} data-test="checkbox-terms" />
            </div>
          }
          label={
            <Typography variant="body1" component="span" data-test="confirmation-text">
              I have read and understand and accept the OpenXSelect Portal Terms of Service.
              <br />
              By checking this box, I agree that I am authorized to accept these Terms of Service on behalf of my
              entity.
            </Typography>
          }
        />
      }
      actionCancel={
        <Button variant="text" onClick={logout} disabled={termsMutation.loading} data-test="cancel-button">
          Cancel
        </Button>
      }
      actionConfirm={
        <Button
          color="primary"
          onClick={onConfirm}
          disabled={!termsAccepted || termsMutation.loading}
          data-test="continue-button"
        >
          Continue
        </Button>
      }
    />
  );
};
