import React, { memo } from 'react';
import { isEqual } from 'lodash';
import { useHistory } from 'react-router-dom';

import { PageHeader, ListHeaderContent } from '@openx/components/header';
import { CriteriaChange, CriteriaDimension, SearchFilters } from '@openx/components/table';
import { useSideDrawer } from 'context';

interface OrganizationsHeaderProps {
  children: React.ReactNode;
  handleCriteriaChange: (props: CriteriaChange<CriteriaDimension.FILTERS>, isInitialCall?: boolean) => void;
}

export const OrganizationsHeaderRaw = ({ children, handleCriteriaChange }: OrganizationsHeaderProps): JSX.Element => {
  const { handleDrawerOpen, openDrawer, menuItems, token } = useSideDrawer();
  const history = useHistory();

  const headerFilters = () => (
    <ListHeaderContent
      filtersComponent={SearchFilters}
      filtersProps={{
        byPhraseFields: ['name'],
        onFiltersChange: (filters, isInitialCall) => {
          handleCriteriaChange({ dimension: CriteriaDimension.FILTERS, value: filters }, isInitialCall);
        },
        inputPlaceholder: 'Search Organization',
      }}
      actions={[
        {
          label: 'New Organization',
          color: 'primary',
          onClick: () => {
            history.push('/organizations/create');
          },
        },
      ]}
    />
  );

  return (
    <PageHeader
      title="Organizations List"
      titlePrefix="OpenXSelect"
      render={headerFilters}
      openDrawer={openDrawer}
      handleDrawerOpen={handleDrawerOpen}
      menuItems={menuItems}
      token={token}
    >
      {children}
    </PageHeader>
  );
};

export const OrganizationsHeader = memo(OrganizationsHeaderRaw, (prevProps, nextProps) =>
  isEqual(prevProps, nextProps),
);
