import { Theme, Typography, makeStyles } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { Button } from '@openx/components/core';
import React from 'react';

const useStyles = makeStyles<Theme>(theme => ({
  downloadButton: {
    marginTop: theme.spacing(2),
  },
  warningAlert: {
    padding: 0,
    color: '#8B5C00',
    margin: '15px 0',
  },
}));

export const Content = () => {
  const { warningAlert, downloadButton } = useStyles();

  return (
    <>
      <Typography variant="h2" gutterBottom data-test="title">
        OpenX Terms & Conditions
      </Typography>
      <Alert className={warningAlert} severity="warning" data-test="alert-message">
        OpenXSelect Terms and Conditions have been updated and are effective as of January 1, 2024.
      </Alert>
      <iframe
        title="terms&conditions"
        src="/assets/terms_and_conditions.pdf"
        width="100%"
        height="500px"
        data-test="iframe-pdf"
      />
      <a
        href="/assets/terms_and_conditions.pdf"
        target="_blank"
        rel="noopener noreferrer"
        download="OpenX Terms&Conditions"
        className={downloadButton}
      >
        <Button data-test="download-pdf">Download as PDF</Button>
      </a>
    </>
  );
};
